import React from 'react';
import { useSelector } from '@xstate/react';

import { useGlobalMachinesContext } from 'contexts';
import { useEffectOnce, useIsEditing } from 'hooks';
import type { CustomLayoutServiceData, UserData } from 'lib/page-props';
import { selectIsInitialLoading, selectVariants } from 'state-machines/cart';

import BaseLayout from './BaseLayout';

interface Props {
	layoutData: CustomLayoutServiceData;
	userInfo?: UserData;
}

export default function LayoutCart({ layoutData, userInfo }: Props) {
	const { route } = layoutData.sitecore;
	const { cartService } = useGlobalMachinesContext();
	const isEditing = useIsEditing();
	useEffectOnce(() => {
		cartService.send('GET_CART');
	});
	const checkoutVariants = useSelector(cartService, selectVariants);
	const initialLoading = useSelector(cartService, selectIsInitialLoading);
	const hasVariants = Boolean(checkoutVariants && checkoutVariants.length > 0);
	return (
		<BaseLayout
			forceNoindexNofollow
			layoutData={layoutData}
			route={route}
			userInfo={userInfo}
			renderMain={isEditing || (!initialLoading && hasVariants)}
			renderEmpty={isEditing || (!initialLoading && !hasVariants)}
			renderLoading={!isEditing && initialLoading}
		/>
	);
}
LayoutCart.displayName = 'LayoutCart';
