import React from 'react';

import type { CustomLayoutServiceData, UserData } from 'lib/page-props';

import BaseLayout from './BaseLayout';

interface Props {
	layoutData: CustomLayoutServiceData;
	userInfo?: UserData;
}

export default function Layout({ layoutData, userInfo }: Props) {
	const { route } = layoutData.sitecore;

	return (
		<BaseLayout layoutData={layoutData} route={route} userInfo={userInfo} />
	);
}
Layout.displayName = 'Layout';
